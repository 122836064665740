<template>
   <div>
      <div
         class="flow-root ring-black ring-opacity-5 rounded border shadow border-gray-25"
         :class="props?.totalCount > 10 ? 'border-b-0 rounded-b-none' : ''"
      >
         <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
               <div class="overflow-hidden">
                  <table class="min-w-full divide-y divide-gray-25">
                     <thead class="bg-white">
                        <tr class="">
                           <template v-if="fromReimbursable">
                              <th
                                 v-for="(header, index) in headers"
                                 :key="index"
                                 scope="col"
                                 :class="
                                    header.title == 'Action' ?
                                       'float-right'
                                    :  'float-left'
                                 "
                                 class="ps-4 w-32 md:py-6 pe-3 text-start text-xs py-3 font-medium text-gray-300 sm:ps-6"
                              >
                                 <div>
                                    {{ header.title }}
                                 </div>
                              </th>
                           </template>
                           <template v-else>
                              <th
                                 v-for="(header, index) in headers"
                                 :key="index"
                                 scope="col"
                                 class="ps-4 w-32 md:py-6 pe-3 text-start text-xs py-3 font-medium text-gray-300 sm:ps-6"
                              >
                                 <div>
                                    {{ header.title }}
                                 </div>
                              </th>
                           </template>
                        </tr>
                     </thead>
                     <tbody class="divide-y divide-gray-25 bg-white">
                        <tr v-for="data in data" :key="data.id" class="left">
                           <template v-if="fromReimbursable">
                              <td
                                 v-for="(header, index) in headers"
                                 :key="index"
                                 :class="
                                    header.title == 'Action' ?
                                       'float-right'
                                    :  'float-left'
                                 "
                                 class="whitespace-nowrap py-4 ps-4 pe-3 text-gray-1000 sm:ps-6 text-sm"
                              >
                                 <div>
                                    <slot
                                       :name="header.value"
                                       :data="data"
                                    ></slot>
                                 </div>
                              </td>
                           </template>
                           <template v-else>
                              <td
                                 v-for="(header, index) in headers"
                                 :key="index"
                                 class="whitespace-nowrap py-4 ps-4 pe-3 text-gray-1000 sm:ps-6 text-sm"
                              >
                                 <div>
                                    <slot
                                       :name="header.value"
                                       :data="data"
                                    ></slot>
                                 </div>
                              </td>
                           </template>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <nav
         v-if="props?.totalCount > 10"
         class="flex items-center justify-between rounded rounded-t-none shadow border border-t-0 border-gray-25 bg-white p-3"
         aria-label="Pagination"
      >
         <div class="hidden sm:block">
            <p class="text-sm text-gray-700">
               Showing
               {{ ' ' }}
               <span class="font-medium">{{ start }}</span>
               {{ ' ' }}
               to
               {{ ' ' }}
               <span class="font-medium">{{ end }}</span>
               {{ ' ' }}
               of
               {{ ' ' }}
               <span class="font-medium">{{ totalCount }}</span>
               {{ ' ' }}
               results
            </p>
         </div>
         <div class="flex flex-1 justify-between sm:justify-end">
            <BaseButton
               @click="prevPage"
               :disabled="page === 1"
               :prepend-icon="ChevronLeftIcon"
               :class="
                  page === 1 ?
                     'cursor-not-allowed border-gray-25 text-gray-700'
                  :  'cursor-pointer'
               "
               class="me-2"
            >
               <span class="font-normal"> Prev </span>
            </BaseButton>
            <BaseButton
               @click="nextPage"
               :append-icon="ChevronRightIcon"
               :disabled="page >= totalPage"
               :class="
                  page >= totalPage ?
                     'cursor-not-allowed border-gray-25 text-gray-700'
                  :  'cursor-pointer'
               "
            >
               <span class="font-normal"> Next </span>
            </BaseButton>
         </div>
      </nav>
   </div>
</template>

<script setup lang="ts">
   import {
      ChevronLeftIcon,
      ChevronRightIcon,
   } from '@heroicons/vue/24/outline';
   import { computed, ref, watch } from 'vue';

   const props = defineProps({
      search: {
         type: String,
      },
      headers: {
         type: Array,
      },
      data: {
         type: Array,
      },
      totalCount: {
         type: [String, Number],
      },

      align: {
         type: String,
      },
      fromReimbursable: {
         type: Boolean,
         default: false,
      },
   });

   const page = defineModel('page');
   const perPage = ref(10);
   const offset = ref(1);

   watch(page, () => {
      offset.value = (page.value - 1) * perPage.value;
   });

   const start = computed(() => {
      return (page.value - 1) * perPage.value + 1;
   });
   const end = computed(() => {
      return Math.min(page.value * perPage.value, props.totalCount);
   });

   const totalPage = computed(() => {
      return Math.ceil(props.totalCount / perPage.value);
   });

   const emits = defineEmits(['next-page', 'prev-page']);

   const nextPage = () => {
      emits('next-page');
   };
   const prevPage = () => {
      emits('prev-page');
   };
</script>
